/* ==========================================================================
   Colours
   ========================================================================== */

   $nearly-black: #10080d;
   $gray: #c8c0c5;
   $light-gray: #edf1f4;
   $dark-gray: #5D7284;
   $primary-color: #2079be;
   $secondary-color: #2079be;

   $button-cta-color: #2079be;
   $link-color: #2079be;


   /* ==========================================================================
      Fonts
      ========================================================================== */

   $primary-font:  Poppins, sans-serif;
   $secondary-font: 'Lato', sans-serif;
   $x-small-space: -.015rem;
   $small-space: 0;
   $med-space: 0.075rem;
   $space: 0.25rem;


/* ==========================================================================
    images files
    ========================================================================== */
    $linkButtonIcon: url(./custom/project/img/arrow-right-blue.svg);
    $starIcon: url(./custom/project/img/star-pink.svg);
    $desktopLogo: url(./custom/project/img/NM-WIPR-logo.png);
    $mobileLogo: url(./custom/project/img/NM-WIPR-logo.png);

    $animate-default: all 0.5s ease;
    $animate-card: all 0.3s ease-out;
    $animate-background: background-color 0.5s ease;

    $header-height: 201px;
